(function($,w){$(function(){"use strict";

	/* ----------------------------- FontLoader --------------------------------- */

		if ( $( 'body' ).hasClass( 'fonts-loaded' ) )
			return;

		var fA = new FontFaceObserver( 'Proza Libre', { weight: 400, style: 'normal' } ),
			fB = new FontFaceObserver( 'Proza Libre', { weight: 500, style: 'normal' } ),
			fC = new FontFaceObserver( 'Proza Libre', { weight: 600, style: 'normal' } ),
			fD = new FontFaceObserver( 'Proza Libre', { weight: 700, style: 'normal' } ),
			fE = new FontFaceObserver( 'Proza Libre', { weight: 800, style: 'normal' } ),
			fF = new FontFaceObserver( 'Proza Libre', { weight: 400, style: 'italic' } ),
			fG = new FontFaceObserver( 'Proza Libre', { weight: 500, style: 'italic' } ),
			fH = new FontFaceObserver( 'Proza Libre', { weight: 600, style: 'italic' } ),
			fI = new FontFaceObserver( 'Proza Libre', { weight: 700, style: 'italic' } ),
			fJ = new FontFaceObserver( 'Proza Libre', { weight: 800, style: 'italic' } );

		Promise
			.all( [ fA.load(), fB.load(), fC.load(), fD.load(), fE.load(), fF.load(), fG.load(), fH.load(), fI.load(), fJ.load() ] )
			.then( function() { $( 'body' ).addClass( 'fonts-loaded' ) } );

	/* ----------------------------- Cookie Link --------------------------------- */

	var privacy = $( 'p.borlabsCookieSubHeadline' ).parent().find( 'a' ).prop( 'target', '_blank' ).first();

	privacy.prop( 'href', privacy.prop( 'href' ) + '#datenschutz' ).text( 'Datenschutz' );

	/* ----------------------------- Sticky Nav --------------------------------- */

		var header = $( '#main-header' ).addClass( 'normal' );

		if ( header.length )
		{

			var winWidth = $(w).width(),
				sticky 	 = false,
				body 	 = $('body').addClass('sticky-nav'),
				main     = $('div#et-main-area'),
				inner 	 = header.find('#et-top-navigation'),
				padding  = inner.css('padding-top'),
				height   = inner.css('height'),
				navTop   = header.offset().top,
				navBar	 = function( $this )
				{
					var top = $this.scrollTop();

					if ( top > navTop + 250 && !sticky )
					{
						header.css('top',0);
						body.addClass('sticky');

						if (header.hasClass('normal'))
						{
							setTimeout( function()
							{
								header
									.addClass('sticky')
									.removeClass('normal')
									.find('#et-top-navigation')
									.css({'padding-top':23, 'height':60});

								main.animate({'paddingTop':70},300);

							}, 200);

						}

						sticky = true;
					}

					else if (top === 0)
					{
						body.removeClass('sticky');
						header.addClass('normal').removeClass('sticky');

						header
							.animate({'top':0},300)
							.find('#et-top-navigation')
							.animate({'padding-top':padding, 'height':height}, 150, function()
							{

								sticky = false;

							});

						main.animate({'paddingTop':130},300);
					}
				}

			$( w ).resize( function()
			{
				winWidth = $(this).width();

				if ( winWidth < 981 )
				{
					if( body.hasClass( 'sticky' ))
						body.removeClass('sticky');

					if( header.hasClass( 'sticky' ))
						header.addClass('normal').removeClass('sticky');

					sticky = false;
				}

				else
				{
					navBar( $(this) );
				}
			});

			$( w ).scroll( function()
			{
				if ( winWidth > 980 )
				{
					navBar( $(this) );
				}
			});
		}

	// /* ----------------------------- End Sticky Nav -------------------------- */


    class dclmMenu
    {

        setProperties()
        {

            this.menu = $( '#offerings' );

        } // end setProperties

        constructor()
        {

            this.setProperties();

            this.bindEvents();

            // this.initialize();

        } // end constructor

        initialize()
        {

            // do all the stuff

        } // end initialize

        bindEvents( self = this )
        {

            self.menu.find( 'h2.et_pb_toggle_title' ).on( 'click', function()
            {

                let parent   = $(this).parent(),
                    subtract = $( window ).width() > 980 ? 80 : 10;

                if ( parent.hasClass( 'et_pb_toggle_close' ) ) {
                    let sibs = parent.siblings( 'div.et_pb_toggle_open' );

                    if ( sibs.length > 0 ) {

                        sibs.children( 'div.et_pb_toggle_content' ).slideUp( 800, function()
                        {

                            sibs.removeClass( 'et_pb_toggle_open' ).addClass( 'et_pb_toggle_close' );

                            $( 'html, body' ).animate({ scrollTop: parent.offset().top - subtract }, 800, 'swing' );

                        });
                    }

                    else {

                        $( 'html, body' ).animate({ scrollTop: parent.offset().top - subtract }, 800, 'swing' );

                    }
                }

            });

        } // end bindEvents
    } // end class dclmMenu

    new dclmMenu


});}(jQuery,window));
